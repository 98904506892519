import React from 'react'
import './LatestEvents.css'
import event_image from '../../images/events.jpg'
const LatestEventCards = () => {
  return (
    <div className='latest-events-card'>
        <div className="latest-events-image-container">
            <img width={340} src={event_image} alt="" />
        </div>
        <div className="latest-events-title">
       Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem, vero!
        </div>
        <div className="latest-events-description">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deleniti, repudiandae!
        </div>
    </div>
  )
}

export default LatestEventCards