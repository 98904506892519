import React, { useState, useEffect } from 'react';
import HeroImage from '../components/HeroImage';
import '../styles/Gallery.css';

import { getGalleryImages } from '../services/homepage.service';
const Gallery = () => {
  const school_infrastructures = [
    { type: 'Laboratory', image: 'images/scholarship.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h1.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h2.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h3.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h4.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h5.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h6.jpeg', name: '' },
  ];
  const [infras, setInfras] = useState(school_infrastructures);
  const getFilterdData = (updatedInfras) => {
    setInfras(updatedInfras);
  };
  const [galleryImages, setGalleryImages] = useState([]);
  useEffect(() => {
    getGalleryImages().then((_images) => setGalleryImages(() => _images));
  }, []);
  return (
    <>
      <HeroImage />
      {console.log(galleryImages)}
      <div
        style={{
          textAlign: 'center',
          // backgroundColor: "#ecf5ff",
          paddingTop: '4.5rem',
          paddingBottom: '4.5rem',
          marginBottom: '30px',
          color: '#0e2245',
        }}>
        <h1>Gallery</h1>
      </div>
      <div className='container'>
        <div className='row'>
          {/* <InfraFilter getFilterdData={getFilterdData} school_infrastructures={school_infrastructures}/> */}
          {/* I have commented out the school infra filter */}
        </div>
        <div className='gallery_container'>
          <div className='row'>
            {galleryImages.map((galleryImage) => (
              <div className='col-lg-3 col-md-6 col-12'>
                <div className='Gallery_Image_container'>
                  <img
                    src={`https://admin.cnsjunior.com//assets/${galleryImage.image}`}
                    alt='schoolImg'
                    width='250px'
                    height='250px'
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Gallery;
