import React from "react";
import HeroImage from "../../components/HeroImage";
import ArtGallery from "../../components/ArtGallery";
import TableComponent from "../../components/TableComponent";
import Heading from "../../components/Heading";
import TitleComponent from "../../components/TitleComponent";
import "../../styles/Administration/Faculty.css";
const Faculty = () => {
  const description = "";
  const teachingStaffs = {
    headings: ["SL.No.", "Name", "Designation", "Qualification"],
    rows: [
      [
        "1",
        "Dr. Gunja Das ",
        "Principal",
        "Phd,M.Sc in Geography,M.A. in Folklore and Education,B.Ed",
      ],
      ["2", "Mrs.Nilanjana Chakraborty", "Headmistress", "M.A.(History),B.Ed."],
      ["3", "Mrs. Bonhi Sengupta", "Vice Principal", "M.A.(English),B.Ed."],
      ["4", "Mrs.Lupa Haldar Biswas", "Librarian", "B.A.,MLi.Sc."],
      ["5", "Mr.Manoj Ghosh", "Teacher", "B.A.(Political Science)"],
      // ["5", "Miss.Rupika Mondal", "Teacher", "M.A.,MTT"],
      ["6", "Mrs.Pallabi Maitra", "J", "M.A.(English),B.Ed."],
      ["7", "Mrs.Lipi Saha", "Teacher", "M.A.(History),B.Ed."],
      ["8", "Mrs.Jiniya Biswas", "Teacher", "M.Sc.,B.Ed."],
      ["9", "Mr.Raju Bhoumick", "Teacher", "M.Sc.(Maths),B.Ed."],
      ["10", "Miss.Ayenti Pal", "Teacher", "M.Sc.(Physics),B.Ed."],
      ["11", "Miss.Taniya Dutta Chowdhury", "Teacher", "M.Sc(Zoology),B.Ed."],
      ["12", "Mr.Nilotpal Sen Bakshi", "Teacher", "B.A.(English),B.Ed."],
      ["13", "Mrs.Aindrila Basak", "Teacher", "M.A.(English),B.Ed."],
      ["14", "Mrs.Parbati Dutta Sarkar", "Teacher", "M.Sc.(Chemistry),B.Ed."],
      ["15", "Miss.Rima Jospina Rozario", "Teacher", "B.A.(English)"],
      ["16", "Mrs.Payel Sarkar", "Teacher", "M.A.(English),B.Ed."],
      ["17", "Miss.Bratati Das", "Teacher", "D.El.Ed."],
      ["18","Pratim Ghosh", "Teacher(Skill subject)", "B.Sc(H) CS"]
    ],
  };
  const nonTeachingStaffs = {
    headings: ["SL.No.", "Name", "SL.No.", "Name"],
    rows: [
      ["1", "Dipika Sarkar", "9", "Jibon Bala"],
      ["2", "Bivas Sarkar", "10", "Dipankar Das"],
      ["3", "Motibul Handu", "11", "Avijit Sen"],
      ["4", "Lila Rani Debnat", "12", "Amal Chatterjee"],
     
      ["5", "Shilpi Das", "14", "Uttam Ghosh"],
      ["6", "Soma Saha", "15", "Rakesh Das"],
      ["7", "Mithun Chakaborty", "16", "Nitya Nanda Biswas"],
    ],
  };
  return (
    <>
      <HeroImage />
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <Heading
              title="Our Faculties"
              description={description}
              colHeading={"col-lg-4"}
              colDescription={"col-lg-8"}
            />
            <TitleComponent data={"Our Teaching Faculties"} />
            <div className="tableScroll">
              <TableComponent
                tableData={teachingStaffs}
                className="tableShadow"
              />
            </div>

            <TitleComponent data={"Our Non-Teaching Faculties"} />
            <div className="tableScroll">
              <TableComponent tableData={nonTeachingStaffs} />
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <ArtGallery />
          </div>
        </div>
      </div>
    </>
  );
};

export default Faculty;
