import React from "react";
import "../styles/TableComponent.css";
function TableComponent(props) {
  const data = props.tableData;
  return (
    <div className="table_container">
      <table className="tableComponent">
        <tr className="table_component_tr">
        {data.headings.map((cell) => {
          return <th className="table_component_th">{cell}</th>;
        })}
        </tr>
        {data.rows.map((row) => {
          return (
            <tr className="table_component_tr">
              {row.map((cell) => (
                <td className="table_component_td">{cell}</td>
              ))}
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default TableComponent;
