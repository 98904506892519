import React from 'react'
import './LatestEvents.css'
import LatestEventCards from './LatestEventCards'

const LatestEvents = () => {
  return (
    <div className="latest-events-container">
        <div className="latest-events-heading">
            Latest Heading
        </div>
        <div className="latest-events-row" >
        <LatestEventCards/>
        <LatestEventCards/>
        <LatestEventCards/>
        </div>
        <div className="latest-events-row" id='latest-event-hide'>
        <LatestEventCards/>
        <LatestEventCards/>
        <LatestEventCards/>
        </div>
    </div>
  )
}

export default LatestEvents